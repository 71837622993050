<template>
  <v-container class="arcvhivosP">
    <label>
      {{ archivos }} :
      <input id="files" ref="file" @change="getFile" type="file" />
    </label>
    <v-btn
      v-if="this.files.length != 0"
      icon
      @click="convertFile"
      x-large
      color="grey"
    >
      <v-icon>mdi-upload</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";

export default {
  name: "CargueExcel",

  props: ["archivos", "url"],

  data() {
    return {
      base64: null,
      files: [],
    };
  },

  methods: {
    getFile() {
      this.files = [];
      // console.log(this.$refs.file.files[0])
      this.files = this.$refs.file.files[0];
    },

    convertFile() {
      var url = this.files;
      var reader = new FileReader();
      reader.readAsDataURL(url);
      reader.onload = () => {
        this.base64 = reader.result;
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "masa");
            fd.append("subtipo", "subir");
            fd.append("archivo", this.base64);
            axios.post(host + this.url, fd).then((result) => {
              let data = result.data;
              if (data.text == "OK") {
                this.$alert("Archivos cargados correctamente");
                this.base64 = null;
                this.files = [];
                this.$refs.file = null;
              } else {
                this.$alert("Error: " + result.data);
              }
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
  },
};
</script>

<style scoped>
.arcvhivosP {
  margin: 5px !important;
}
</style>